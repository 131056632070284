body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.box-size {
  max-width: 600px !important;
}
.box-size-2 {
  max-width: 1020 !important;
}
.container-design {
  background-color: #efefef;
    padding: 25px;
    border-radius: 10px;
}
.card-main {
    justify-content: center;
    text-align: center;
    align-items: center;
    /* color: rgb(0, 0, 0); */
    font-weight: 800;
    border: none;
    text-transform: uppercase;
    height: 70px !important;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 5px;
}
.card-main .card-body {
  line-height: 15px !important;
  display: flex;
  align-items: center;
}
.group-success {
    text-align: center;
}
.game-group {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: center;
}
.bg1{background-color: #bb80c6;} 
.bg2{background-color: #a0c35a;} 
.bg3{background-color: #b2c4f0;} 
.bg4{background-color: #fbe06e;}

.how-to-play-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.how-to-play-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.how-to-play-card .card-body {
  transition: background-color 0.3s ease;
}

.how-to-play-card:hover .card-body {
  background-color: #f8f9fa;
}

/* KeyFeatures.css */

/* General Styles */
.key-features-section {
  background-color: #f9f9f9;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #343a40;
}

.section-subtitle {
  font-size: 1.2rem;
  color: #6c757d;
  max-width: 700px;
  margin: 0 auto;
}

/* Feature Card Styles */
.feature-card {
  text-align: center;
  border: none;
  background-color: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding-top: 30px;
  border-radius: 10px;
  position: relative;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.icon-wrapper {
  width: 80px;
  height: 80px;
  background-color: #007bff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 20px auto;
  font-size: 2rem;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
}

.feature-card .card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #343a40;
  margin-top: 40px;
}

.feature-card .card-text {
  color: #6c757d;
  font-size: 1rem;
  margin-top: 15px;
}

/* Conclusion Section Styles */
.conclusion-section {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
}

.conclusion-section h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 20px;
}

.conclusion-section p {
  font-size: 1.1rem;
  color: #6c757d;
  max-width: 800px;
  margin: 0 auto 30px auto;
}

.faq-section {
  padding: 2rem;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  max-width: 800px;
  margin: 30px auto;
}

.faq-heading {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
}

.faq-card {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.accordion-button {
  background-color: #e0e0e0;
  color: #fff;
  border: none;
}

.accordion-button:not(.collapsed) {
  background-color: #e0e0e0;
  color: #fff;
}

.accordion-body {
  background-color: #fff;
  color: #333;
}

.about-us {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 5px;
}

.about-us h1 {
  font-size: 2.5rem;
  color: #343a40;
}

.about-us p {
  font-size: 1.1rem;
  color: #495057;
}

.about-us a {
  color: #007bff;
  text-decoration: none;
}

.about-us a:hover {
  text-decoration: underline;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* Center vertically */
}

.spinner {
  border: 8px solid #f3f3f3; /* Light gray background */
  border-top: 8px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
}
.privacy-policy {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #efefef;
  border-radius: 10px;
}

.privacy-policy h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-policy h3 {
  font-size: 1.5rem;
  margin-top: 15px;
}

.privacy-policy p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}
.contact-us {
  margin: 20px auto;
  max-width: 900px;
  padding: 20px;
}

.contact-us h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-us h2 {
  font-size: 2rem;
  margin-top: 20px;
}

.contact-us p {
  font-size: 1rem;
  line-height: 1.6;
}

.contact-us ul {
  list-style-type: none;
  padding: 0;
}

.contact-us li {
  margin-bottom: 10px;
}

.contact-us .alert {
  margin-bottom: 20px;
}
.row.rowP>* { 
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.cardMainDesign {
  box-shadow: 0px -4.04px 58.75px 2.69px #FFFFFF40;
  border-radius: 25px;
  background-color: #f000;
  margin: 15px auto !important;

}
.blog-detail {
  margin-top: 80px;
  margin-bottom: 80px;
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  padding: 50px 80px !important;
}
.blog-detail img.img_banner {
  text-align: center;
  margin: 25px auto;
  width: 100%;
  border-radius: 50px;
}
.author {
  align-items: center;
  color: var(--themeColor2);
  display: flex;
  line-height: 1.4;
  margin: auto;
  padding: 1rem;
  font-weight: 600;

}

.author a {
  color: var(--themeColor2);
  font-weight: 600;
}

.author__avatar {
  border-radius: 50%;
  display: block;
  height: 4rem;
  margin-right: 1rem;
  width: 4rem;
}
.toc {
  margin: 30px auto;
  padding: 20px;
  background-color:  #dfdfdfb7;
  border-radius: 20px;
  cursor: pointer;
}
.toc-header svg {
  margin: 0 5px;
}
.toc h4 {
  margin-bottom: 0px;
}

.toc ul {
  list-style-type: none;
  padding: 10px 40px;
}

.toc-item.h2 a {
  font-weight: bold;
  color: #2b2b2b;
  text-decoration: none;
}

.toc-item.h3 a {
  padding-left: 10px;
  font-weight: normal;
  color: #2b2b2b;
  text-decoration: none;
}
.toc-item.h2 {
  font-size: 20px;
}
.toc-item.h3 {
  font-size: 16px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 600px) {
  .start-card-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .card-main {
      font-size: 12px;
  }
  .card-body { 
      padding: 8px !important;
  }
  .box-size-2 {
    max-width: 600px !important;
  }
}